<template>
    <div class="sign-up">
        <div class="container">
            <template v-if="step === 1">
                <form class="form" @submit.prevent="googleSignUp">
                    <button class="submit-button ww-editor-button -secondary" type="submit">
                        <template v-if="!isLoading">
                            <wwEditorIcon class="ww-editor-button-icon -left" name="google" large />
                            Sign up with Google
                        </template>
                        <span v-else>
                            <wwVeryLittleLoader />
                        </span>
                    </button>
                </form>
                <div class="sign-up__separator">
                    <span class="sign-up__separator--left"></span>
                    <span class="sign-up__separator--label">or</span>
                    <span class="sign-up__separator--right"></span>
                </div>
            </template>
            <form class="form" @submit.prevent="signUpEmail" v-if="step === 1">
                <label class="auth-label" for="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    class="auth-input ww-editor-input -large"
                    v-model="email"
                    placeholder="name@company.com"
                    required
                    autofocus
                    autocomplete="email"
                />
                <div v-if="error" class="error-message label-s" v-html="error"></div>
                <button class="submit-button ww-editor-button -primary" type="submit">
                    <wwEditorIcon class="ww-editor-button-icon -left" name="envelop" large />
                    Sign up with Email
                </button>
                <span class="rgpd label-xs">
                    By signing up, you agree to our
                    <a class="ww-editor-link" href="//www.weweb.io/terms-and-conditions/" target="_blank">
                        General Terms & Conditions
                    </a>
                    <br />
                    and to our
                    <a class="ww-editor-link" href="//www.weweb.io/privacy-policy/" target="_blank"> Privacy Policy </a>
                </span>
            </form>
            <form class="form" @submit.prevent="signUp" v-else-if="step === 2">
                <label class="auth-label" for="name">Full name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    class="auth-input -mb -mt ww-editor-input -large"
                    v-model="fullname"
                    placeholder="First and Last"
                    required
                    autocomplete="name"
                    aria-label="Name"
                />
                <label class="auth-label" for="current-password"> Password </label>
                <input
                    type="password"
                    id="current-password"
                    name="current-password"
                    class="auth-input ww-editor-input -large"
                    v-model="password"
                    placeholder="••••••••••••"
                    required
                    autocomplete="current-password"
                />
                <div v-if="error" class="error-message label-s" v-html="error"></div>
                <button class="submit-button ww-editor-button -primary" type="submit">
                    <template v-if="!isLoading"> Continue </template>
                    <span v-else>
                        <wwVeryLittleLoader />
                    </span>
                </button>
                <span class="rgpd label-xs">
                    By signing up, you agree to our
                    <a class="ww-editor-link" href="//www.weweb.io/terms-and-conditions/" target="_blank">
                        General Terms & Conditions
                    </a>
                    <br />
                    and to our
                    <a class="ww-editor-link" href="//www.weweb.io/privacy-policy/" target="_blank"> Privacy Policy </a>
                </span>
            </form>
            <div class="bottom-action label-button">
                <router-link class="ww-editor-link" to="/sign-in">Back to Sign in</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import services from '@/services';
import wwVeryLittleLoader from '@/components/elements/wwVeryLittleLoader.vue';

const PASSWORD_REGEX =
    /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/gm;
const FULLNAME_NOT_ALLOWED_REGEX = /[/.:]/gm;

export default {
    name: 'SignUp',
    components: { wwVeryLittleLoader },
    data() {
        return {
            email: '',
            fullname: '',
            password: '',
            error: '',
            isLoading: false,
            step: 1,
        };
    },
    computed: {
        ...mapGetters({
            user: 'v2/getUser',
        }),
    },
    methods: {
        signUpEmail() {
            this.step = 2;
            if (window.analytics) window.analytics.track('SIGNUP_FORM_STEP_1_VALIDATED', {});
        },
        async signUp() {
            if (this.isLoading) return;
            if (this.password.length > 72) {
                this.error = 'Password must not contain more than 72 characters.';
                throw new Error();
            }
            if (this.fullname.match(FULLNAME_NOT_ALLOWED_REGEX)) {
                this.error = "User name cannot contain '/', '.' or ':'";
                throw new Error();
            }
            if (!this.password.match(PASSWORD_REGEX)) {
                this.error =
                    'Password must contain at least 8 characters including 1 lowercase, 1 uppercase, 1 number, 1 special character.';
                throw new Error();
            }
            this.error = '';
            this.isLoading = true;
            try {
                await services.api.user.signUpUser(
                    this.fullname,
                    this.email,
                    this.password,
                    this.$route.params.referralId
                );

                this.isLoading = false;
                await this.signIn();
                if (window.analytics) {
                    window.analytics.identify(this.user.id, {
                        email: this.email,
                        full_name: this.fullname,
                        name: this.fullname,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    });
                    window.analytics.track('SIGNUP_FORM_STEP_2_VALIDATED', {});
                }
            } catch (err) {
                wwLib.wwLog.error(err);
                switch (
                    (err.graphQLErrors &&
                        err.graphQLErrors[0] &&
                        err.graphQLErrors[0].extensions &&
                        err.graphQLErrors[0].extensions.code) ||
                    true
                ) {
                    case 'EMAIL_ALREADY_TAKEN':
                        this.step = 1;
                        this.error =
                            'This email is taken. Would you like to sign-in instead? <a class="ww-editor-link" href="/sign-in">Sign in</a>';
                        break;
                    case 'EMAIL_DOMAIN_INVALID':
                        this.step = 1;
                        this.error = 'Invalid email domain. Please use a valid email address.';
                        break;
                    default:
                        this.error =
                            'Unknown error, please contact support@weweb.io. This can be caused by VPN or an enterprise network.';
                }
                this.isLoading = false;
                throw err;
            }
        },
        async signIn() {
            if (this.isLoading) return;

            this.isLoading = true;
            try {
                await services.api.user.loginManager(this.email, this.password);

                await this.$store.dispatch('v2/fetchUser');

                this.isLoading = false;
                this.$router.replace({ name: 'email-verification' });
            } catch (err) {
                this.error =
                    'Unknown error, please contact support@weweb.io. This can be caused by VPN or an enterprise network.';
                wwLib.wwLog.error(err);
                this.isLoading = false;
                throw err;
            }
        },
        googleSignUp() {
            window.location.href =
                process.env.VUE_APP_API_URL +
                '/users/google/login' +
                (this.$route.params.referralId ? '?referralId=' + this.$route.params.referralId : '');
        },
    },
};
</script>

<style scoped lang="scss">
.sign-up {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    &__separator {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        user-select: none;
        &--left,
        &--right {
            border: 1px solid var(--ww-color-dark-300);
            width: 100%;
            height: 1px;
        }
        &--label {
            font-size: var(--ww-font-size-03);
            color: var(--ww-color-dark-500);
            margin: 0 var(--ww-spacing-02);
        }
        margin-bottom: var(--ww-spacing-04);
    }
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: var(--ww-spacing-04);
            width: 100%;
            .auth-label {
                margin-bottom: var(--ww-spacing-02);
                font-size: var(--ww-font-size-03);
                color: var(--ww-color-dark-500);
            }
            .auth-input {
                width: 100%;
                margin-bottom: var(--ww-spacing-03);
            }
            .error-message {
                width: 100%;
                text-align: center;
                color: var(--ww-color-red-500);
            }
            .submit-button {
                width: 100%;
                margin-top: var(--ww-spacing-03);
            }
            .rgpd {
                width: 100%;
                text-align: center;
                margin-top: var(--ww-spacing-02);
                color: var(--ww-color-dark-500);
            }
        }
        .bottom-action {
            margin: var(--ww-spacing-03) 0;
        }
    }
}
</style>
