import gql from 'graphql-tag';
/*=============================================m_ÔÔ_m=============================================\
    Organizations
\================================================================================================*/
export const ORGANIZATION_FRAGMENT = gql`
    fragment OrganizationFragment on Organization {
        id
        name
        role
        selfHostSettings
        stripeCustomerId
        plan
        isPlanCancelled
        planInterval
        planDate
        stripeSubscriptionId
        features
        gitConfig
        createdAt
        updatedAt
    }
`;
