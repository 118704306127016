<template>
    <div class="progress">
        <div class="flex items-end">
            <div class="label-m text-stale-500">{{ label }}</div>
            <div class="label-s text-stale-500 ml-auto">
                <slot name="append-label" />
            </div>
        </div>
        <div class="bg-stale-300 ww-border-radius-02 overflow-hidden w-100 mt-2" style="height: 8px">
            <div
                class="ww-border-radius-02 overflow-hidden"
                :class="getColor((value / max) * 100)"
                style="height: 8px"
                :style="{ width: (value / max) * 100 + '%' }"
            ></div>
        </div>
        <div class="flex items-center caption-s text-stale-500 mt-1">
            <slot name="text" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Number, default: 0 },
        max: { type: Number, default: 0 },
        label: { type: String, default: '' },
    },
    methods: {
        getColor(nbr) {
            if (nbr >= 100) return 'bg-red-500';
            else if (nbr >= 75) return 'bg-yellow-500';
            else return 'bg-blue-500';
        },
    },
};
</script>
