<template>
    <div class="ww-popup-workspace-contact-us">
        <label class="contact-us__label caption-s" for="commit">
            Message:
            <span class="contact-us__label-required">{{ $t('utils.required') }}</span>
        </label>
        <textarea
            rows="15"
            class="contact-us__input ww-editor-input -large"
            name="message"
            v-model="message"
            placeholder="Enter a message"
        />
        <div class="label-m text-stale-500 mt-2">
            <div v-if="options.data.contactType === 'RETRIEVE_PENDING_PAYOUT'">
                Please contact us directly to retrieve your pending payout.
                <br />
            </div>
            We will get back to you within 24 business hours.
        </div>
    </div>
</template>

<script>
import services from '@/services';
import { mapGetters } from 'vuex';

export default {
    name: 'wwPopupWorkspaceContactUs',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            message: '',
        };
    },
    computed: {
        ...mapGetters({
            workspace: 'v2/getCurrentOrganization',
            user: 'v2/getUser',
        }),
    },
    watch: {
        message: {
            immediate: true,
            handler() {
                this.setButtonState();
            },
        },
    },
    methods: {
        setButtonState() {
            const message = this.message.trim();
            if (message.length) {
                this.options.setButtonState('SEND', 'ok');
            } else {
                this.options.setButtonState('SEND', 'disabled');
            }
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            try {
                await services.api.workspace.contactUs(
                    this.workspace.id,
                    this.user.id,
                    this.options.data.contactType,
                    this.message
                );
            } catch (err) {
                wwLib.wwLog.error(err);
            }

            await new Promise(resolve => {
                setTimeout(() => {
                    this.options.setLoadingStatus(false);
                    resolve();
                }, 1000);
            });
        },
    },
};
</script>

<style scoped lang="scss">
.ww-popup-workspace-contact-us {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;
    .contact-us {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__input {
            margin-bottom: var(--ww-spacing-03);
            resize: none;
        }
        &__error {
            color: var(--ww-color-red-500);
        }
    }
}
</style>
