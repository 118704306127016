<template>
    <div class="ww-box w-100 flex items-center p-4 mb-4">
        <svg v-if="!small" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2959_21028)">
                <circle cx="36" cy="36" r="36" fill="url(#paint0_linear_2959_21028)" />
                <path
                    opacity="0.5"
                    d="M9.80979 13.5854C9.86966 13.4011 10.1303 13.4011 10.1902 13.5854L11.0777 16.3167C11.1044 16.3991 11.1812 16.4549 11.2679 16.4549H14.1397C14.3335 16.4549 14.414 16.7028 14.2573 16.8167L11.9339 18.5048C11.8638 18.5557 11.8345 18.646 11.8613 18.7284L12.7487 21.4597C12.8086 21.6439 12.5977 21.7972 12.4409 21.6833L10.1176 19.9952C10.0475 19.9443 9.95254 19.9443 9.88244 19.9952L7.55905 21.6833C7.40231 21.7972 7.19141 21.6439 7.25129 21.4597L8.13874 18.7284C8.16552 18.646 8.13618 18.5557 8.06609 18.5048L5.7427 16.8167C5.58595 16.7028 5.66651 16.4549 5.86025 16.4549H8.73212C8.81877 16.4549 8.89556 16.3991 8.92233 16.3167L9.80979 13.5854Z"
                    fill="#6554C0"
                />
                <path
                    opacity="0.2"
                    d="M42.8098 5.58541C42.8697 5.40115 43.1303 5.40115 43.1902 5.58541L43.6286 6.93475C43.6554 7.01716 43.7322 7.07295 43.8189 7.07295H45.2376C45.4314 7.07295 45.5119 7.32087 45.3552 7.43475L44.2074 8.26869C44.1373 8.31962 44.1079 8.40989 44.1347 8.4923L44.5731 9.84164C44.633 10.0259 44.4221 10.1791 44.2654 10.0652L43.1176 9.23131C43.0475 9.18038 42.9525 9.18038 42.8824 9.23131L41.7346 10.0652C41.5779 10.1791 41.367 10.0259 41.4269 9.84164L41.8653 8.4923C41.8921 8.40989 41.8627 8.31962 41.7926 8.26869L40.6448 7.43475C40.4881 7.32087 40.5686 7.07295 40.7624 7.07295H42.1811C42.2678 7.07295 42.3446 7.01716 42.3714 6.93475L42.8098 5.58541Z"
                    fill="#6554C0"
                />
                <path
                    opacity="0.6"
                    d="M64.6196 19.1708C64.7393 18.8023 65.2607 18.8023 65.3804 19.1708L66.2573 21.8695C66.3108 22.0343 66.4644 22.1459 66.6377 22.1459H69.4753C69.8628 22.1459 70.0239 22.6417 69.7104 22.8695L67.4147 24.5374C67.2745 24.6392 67.2159 24.8198 67.2694 24.9846L68.1463 27.6833C68.266 28.0518 67.8442 28.3583 67.5308 28.1305L65.2351 26.4626C65.0949 26.3608 64.9051 26.3608 64.7649 26.4626L62.4692 28.1305C62.1558 28.3583 61.734 28.0518 61.8537 27.6833L62.7306 24.9846C62.7841 24.8198 62.7255 24.6392 62.5853 24.5374L60.2896 22.8695C59.9761 22.6417 60.1372 22.1459 60.5247 22.1459H63.3623C63.5356 22.1459 63.6892 22.0343 63.7427 21.8695L64.6196 19.1708Z"
                    fill="#6554C0"
                />
                <ellipse cx="35" cy="77" rx="61" ry="22" fill="url(#paint1_linear_2959_21028)" />
                <path
                    d="M39.2771 22.3065C41.5543 20.6161 44.7504 22.4614 44.4251 25.2787L42.7985 39.3653C42.4421 42.4521 39.0402 44.1635 36.3492 42.6099L28.3058 37.966C25.6148 36.4123 25.396 32.6105 27.891 30.7585L39.2771 22.3065Z"
                    fill="#6554C0"
                />
                <path
                    d="M27.3778 46.2086C27.7495 47.1256 28.6969 47.6726 29.6769 47.536L33.5284 46.9992C35.5636 46.7156 36.0895 44.0181 34.31 42.9907L28.9976 39.9235C27.218 38.8961 25.1449 40.7004 25.9169 42.6047L27.3778 46.2086Z"
                    fill="url(#paint2_linear_2959_21028)"
                />
                <circle cx="38.806" cy="29.0687" r="2.15926" transform="rotate(30 38.806 29.0687)" fill="#E5E3F4" />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_2959_21028"
                    x1="36"
                    y1="0"
                    x2="36"
                    y2="72"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#E5E3F4" />
                    <stop offset="1" stop-color="#EDEBFC" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2959_21028"
                    x1="35"
                    y1="55"
                    x2="35"
                    y2="77"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#2F2565" />
                    <stop offset="1" stop-color="#1E1740" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2959_21028"
                    x1="28.4517"
                    y1="47.0033"
                    x2="32.8683"
                    y2="39.826"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#6554C0" stop-opacity="0" />
                    <stop offset="0.776986" stop-color="#6554C0" stop-opacity="0.5" />
                    <stop offset="1" stop-color="#6554C0" />
                </linearGradient>
                <clipPath id="clip0_2959_21028">
                    <rect width="72" height="72" rx="36" fill="white" />
                </clipPath>
            </defs>
        </svg>
        <div :class="{ 'ml-5': !small }">
            <div class="label-l text-stale-900">{{ title }}</div>
            <div v-if="description" class="caption-s text-stale-600" :class="small ? 'mt-1' : 'mt-2'">
                {{ description }}
            </div>
        </div>
        <a v-if="contact" class="ww-editor-button -primary -purple ml-auto" href="https://cal.com/slavopastor-ww/30min">
            <b>Talk to us</b>
        </a>
        <div v-else class="ml-auto" @click="setAnalyticsCookie">
            <router-link
                class="ww-editor-button -primary"
                :class="small ? '-dark -small' : '-purple'"
                :to="{ name: pageName }"
            >
                <b v-if="type === 'workspace'"> See seat plans </b>
                <b v-else-if="hasPlan">Upgrade now</b>
                <b v-else>Compare plans</b>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true },
        description: { type: String, default: null },
        type: { type: String, default: 'workspace' },
        upgradeType: { type: String, default: '' },
        contact: { type: Boolean, default: false },
        small: { type: Boolean, default: false },
        cookieValue: { type: String, default: '' },
    },
    computed: {
        hasPlan() {
            if (this.type === 'project') return (this.$store.getters['v2/getCurrentDesign'].plan || {}).stripeProductId;
            else return (this.$store.getters['v2/getCurrentOrganization'].plan || {}).stripeProductId;
        },
        pageName() {
            return this.type === 'project' ? 'workspaces-projects-plans' : 'workspaces-plans';
        },
    },
    methods: {
        setAnalyticsCookie() {
            if (!this.cookieValue || this.type !== 'workspace') return;
            this.$cookies.set(
                `ww-seat-plan-upgrade-source-${this.$store.getters['v2/getCurrentOrganization'].id}`,
                this.cookieValue,
                '15d',
                '/',
                process.env.VUE_APP_HOSTNAME
            );
        },
    },
};
</script>
