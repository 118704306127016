<template>
    <div class="relative">
        <div class="paragraph-l mb-4 pt-6" style="border-top: 1px solid var(--ww-color-dark-300)">Check your email</div>
        <div class="caption-m text-stale-500">
            We've sent an email to verify your email address. Click the link in your inbox to continue.
        </div>
        <button class="ww-editor-button -primary mx-auto mt-6" @click="sendEmail">Resend email</button>
        <button class="ww-editor-button -secondary mx-auto mt-3" @click="checkEmailVerified">
            I have verified my email
        </button>
        <div v-if="errorMsg" class="mt-2 text-red-500 label-m">
            {{ errorMsg }}
        </div>
    </div>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL.replace('/v1', '/v2');

export default {
    data() {
        return {
            errorMsg: '',
        };
    },
    computed: {
        user() {
            return this.$store.getters['v2/getUser'];
        },
    },
    methods: {
        async sendEmail() {
            try {
                await axios.post(`${API_URL}/users/verification/resend`);
                wwLib.wwNotification.open({ text: { en: 'Verification email sent.' }, color: 'green' });
            } catch (err) {
                wwLib.wwLog.error(err);
                wwLib.wwNotification.open({
                    text: { en: 'An error occured while sending the verification email.' },
                    color: 'red',
                });
            }
        },
        async checkEmailVerified() {
            this.errorMsg = '';
            await this.$store.dispatch('v2/fetchUser');
            if (this.user.isEmailVerified) {
                this.$router.push('/sign-up-after');
            } else {
                this.errorMsg = 'It seems your email is not verified, can you check your inbox again?';
            }
        },
    },
    mounted() {
        if (this.user.isEmailVerified) {
            this.$router.push('/workspaces');
        }
    },
};
</script>
