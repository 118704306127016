<template>
    <div class="relative">
        <div class="paragraph-l mb-4 pt-6" style="border-top: 1px solid var(--ww-color-dark-300)">
            Your plan has been updated successfully
        </div>
        <div>
            <router-link v-if="workspaceId" class="ww-editor-link" :to="`/workspaces/${workspaceId}/plans-billing`">
                Back to plans & billing
            </router-link>
            <div v-else class="caption-m text-stale-500">You can close this tab and go back to the editor.</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            workspaceId: null,
        };
    },
    async mounted() {
        this.isLoading = true;
        this.workspaceId = this.$route.query.workspaceId;
    },
};
</script>
