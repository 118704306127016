import gql from 'graphql-tag';

/*=============================================m_ÔÔ_m=============================================\
    User
\================================================================================================*/
export const USER_FRAGMENT = gql`
    fragment UserFragment on User {
        id
        email
        name
        admin
        picture
        organizations {
            id
            name
            role
        }
        beta
        isEmailVerified
        supportChatHash
        createdAt
    }
`;
