<template>
    <div class="sidebar" ref="sidebarContainer">
        <div class="top-side">
            <transition name="profileAppear" mode="out-in">
                <div id="profile" v-show="profileLoaded">
                    <div class="profileInfo-and-options-container">
                        <div class="profile-info">
                            <div class="user-avatar">
                                <div
                                    v-if="user.picture"
                                    class="user-avatar__image"
                                    :style="{ backgroundImage: `url('${user.picture}')` }"
                                />
                                <p v-else>{{ avatarLetter }}</p>
                            </div>
                            <div class="user-info">
                                <div class="name">{{ user.name }}</div>
                                <div class="email">{{ user.email }}</div>
                            </div>
                        </div>
                        <div class="options-container">
                            <wwEditorIcon class="from" name="ellipsis-horizontal-sharp" @click="toggleOptions" />
                            <transition name="toggleModal" mode="out-in">
                                <div class="options-popups" v-if="optionsVisible">
                                    <router-link class="options-link" to="/account-settings">
                                        {{ $t('accountSettings.accountSettings') }}
                                    </router-link>
                                    <a v-if="user.admin" class="options-link" :href="adminUrl" target="_blank">
                                        Admin app
                                    </a>
                                    <router-link v-if="user.admin" class="options-link" to="/admin">
                                        Admin view
                                    </router-link>
                                    <span class="options-link link signout" @click="logout">
                                        {{ $t('utils.logout') }}
                                    </span>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <!-- Workspaces -->
        <div class="workspaces">
            <div class="workspaces-label paragraph-s">Workspaces</div>
        </div>
        <div class="workspaces-list ww-scroll-bar">
            <router-link
                class="workspace-item workspace-link"
                :to="`/workspaces/${item.id}`"
                v-for="(item, index) in sortedOrganizations"
                :key="index"
            >
                <div class="workspace-avatar label-s" :style="randomOrganizationsAvatar(index)">
                    {{ organizationsFirstLetter(item.name) }}
                </div>
                <div class="workspace-name label-l">
                    {{ item.name }}
                </div>
                <wwWorkspaceFlair class="ml-2" :plan="item.plan" small />
            </router-link>
        </div>
        <div class="workspace-item workspace-create label-l" @click="openPopupCreation">
            <wwEditorIcon class="icon" name="add" large /> Add a workspace
        </div>
        <div class="relative mt-auto px-4 pb-4">
            <wwLoader :loading="isUsageLoading" v-if="isUsageLoading" />
            <ProgressBar
                v-else-if="isInFreeTokenWorkspace"
                class="mt-3"
                :value="usage.tokens.user.used"
                :max="usage.tokens.user.total"
                label="Free AI Tokens Usage"
            >
                <template slot="append-label">
                    {{ getCompactNumber(usage.tokens.user.used) }}/{{ getCompactNumber(usage.tokens.user.total) }}
                </template>
                <template slot="text">
                    Free AI Tokens refresh on {{ formatDate(usage.tokens.nextPeriod) }}
                </template></ProgressBar
            >
        </div>
        <div class="resources">
            <div class="resources-label paragraph-s">Learning and resources</div>
        </div>
        <div v-for="(item, index) in resources" :key="index" class="resource-item-container">
            <a v-if="item.link" class="resource-item -small" :href="item.link" target="_blank">
                <div
                    class="resource-icon label-s"
                    :style="{ '--icon-color': `var(${item.iconColor || ''}, --ww-color-dark-500)` }"
                >
                    <i :class="item.icon"></i>
                </div>
                <div class="resource-name label-l">{{ item.label }}</div>
            </a>
            <router-link v-else-if="item.routerLink" class="resource-item -small" :to="item.routerLink">
                <div
                    class="resource-icon label-s"
                    :style="{ '--icon-color': `var(${item.iconColor || ''}, --ww-color-dark-500)` }"
                >
                    <i :class="item.icon"></i>
                </div>
                <div class="resource-name label-l">{{ item.label }}</div>
            </router-link>
            <div
                v-else
                class="resource-item -small"
                :style="{
                    backgroundColor: `var(${item.bgColor})`,
                    color: `var(${item.color})`,
                    '--icon-color': `var(${item.iconColor || ''}, --ww-color-dark-500)`,
                }"
                @click="item.action"
            >
                <div class="resource-icon label-s">
                    <i :class="item.icon"></i>
                </div>
                <div class="resource-name label-l">{{ item.label }}</div>
            </div>
        </div>
        <div class="mt-2"></div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProgressBar from '../views/workspaces/workspace/ProgressBar.vue';
import moment from 'moment';

const URL = process.env.VUE_APP_API_URL.replace('/v1', '/v2');
const COLORS = [
    'var(--ww-color-red-500)',
    'var(--ww-color-yellow-500)',
    'var(--ww-color-green-500)',
    'var(--ww-color-river-500)',
    'var(--ww-color-blue-500)',
    'var(--ww-color-purple-500)',
];

export default {
    components: {
        ProgressBar,
    },
    data() {
        return {
            isHelpOpen: false,
            orgaColors: [],
            optionsVisible: false,
            isUsageLoading: false,
            usage: {
                tokens: {
                    user: {},
                    workspace: {},
                },
            },
        };
    },
    props: {
        title: String,
    },
    computed: {
        ...mapGetters({
            user: 'v2/getUser',
            organizations: 'v2/getOrganizations',
            workspace: 'v2/getCurrentOrganization',
        }),
        avatarLetter() {
            if (this.user && this.user.name) {
                return this.user.name.charAt(0).toUpperCase();
            }
            return '';
        },
        profileLoaded() {
            return this.user.name ? true : false;
        },
        adminUrl() {
            return process.env.VUE_APP_ADMIN_URL;
        },
        sortedOrganizations() {
            return [...this.organizations].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        },
        resources() {
            return [
                {
                    label: 'Book onboarding session',
                    link: 'https://cal.com/weweb-support/onboarding',
                    icon: 'fas fa-users',
                    iconColor: '--ww-color-blue-500',
                },
                {
                    label: 'Referrals',
                    routerLink: '/referrals',
                    icon: 'fas fa-users',
                    iconColor: '--ww-color-blue-500',
                },
                {
                    label: 'Learn with the academy',
                    link: 'https://academy.weweb.io',
                    icon: 'fas fa-graduation-cap',
                    iconColor: '--ww-color-blue-500',
                },
                {
                    label: 'Documentation',
                    link: 'https://docs.weweb.io',
                    icon: 'fas fa-file',
                    iconColor: '--ww-color-green-500',
                },
                {
                    label: 'Ask the community',
                    link: 'https://community.weweb.io',
                    icon: 'fas fa-question-circle',
                    iconColor: '--ww-color-yellow-500',
                },
                {
                    label: 'Report a bug',
                    link: 'https://support.weweb.io/',
                    icon: 'fas fa-exclamation-triangle',
                    iconColor: '--ww-color-red-500',
                },
                {
                    label: 'Chat with us',
                    action: () => {
                        window.userflow.openResourceCenter();
                    },
                    icon: 'fas fa-comments',
                    iconColor: '--ww-color-purple-500',
                    color: '--ww-color-purple-500',
                    bgColor: '--ww-color-purple-100',
                },
            ].filter(Boolean);
        },
        isInFreeTokenWorkspace() {
            return !this.workspace.features?.tokens;
        },
    },
    methods: {
        ...mapActions({
            getOrganizations: 'v2/getOrganizations',
            createOrganizationAct: 'v2/createOrganization',
            logoutUser: 'v2/logoutUser',
        }),
        handleOptionsModal() {
            document.addEventListener('click', event => this.clickListener(event));
        },
        clickListener(event) {
            if (
                event.target.nodeName === 'use' ||
                event.target.nodeName === 'svg' ||
                event.target.classList.contains('langSelect-container') ||
                event.target.classList.contains('langSelect')
            ) {
                return;
            }

            if (this.optionsVisible === true) {
                this.optionsVisible = false;
            }
            if (this.isLangVisible === true) {
                this.isLangVisible = false;
            }
        },
        toggleOptions() {
            this.optionsVisible = !this.optionsVisible;
        },
        organizationsFirstLetter(org) {
            if (org) {
                return org.charAt(0).toUpperCase();
            }
            return '';
        },
        randomOrganizationsAvatar(index) {
            return `background-color: ${COLORS[index % COLORS.length]}`;
        },
        async logout() {
            await this.logoutUser();
            this.$router.push({ path: '/sign-in' });
        },
        // Organizations
        async createOrganization(name) {
            this.isLoading = true;
            try {
                const organization = await this.createOrganizationAct(name);
                if (!organization) throw new Error('FAILED_TO_CREATE_ORGANIZATION');
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Organization created</b>',
                        fr: '<b>Organization créée</b>',
                    },
                    color: 'green',
                    duration: 5000,
                });
                this.$router.push({
                    path: `/workspaces/${organization.id}`,
                });
            } catch (err) {
                wwLib.wwLog.error(err);
                this.isLoading = false;
                if (err && err.graphQLErrors) {
                    for (const e of err.graphQLErrors) {
                        switch (e.extensions.code) {
                            case '409: Conflict':
                                return wwLib.wwNotification.open({
                                    text: {
                                        en: '<b>Organization name already exist.</b> ',
                                        fr: "<b>Le nom de l'organisation existe déjà.</b>",
                                    },
                                    color: 'orange',
                                    duration: '5000',
                                });
                        }
                    }
                }
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Error while creating the organization</b>',
                        fr: "<b>Erreur lors de la creation de l'organization</b>",
                    },
                    color: 'red',
                    duration: 5000,
                });
            }
        },
        async openPopupCreation() {
            try {
                const options = { firstPage: 'WORKSPACE_CREATE' };
                const result = await wwLib.wwPopups.open(options);
                if (result && result.organizationName) {
                    this.createOrganization(result.organizationName);
                }
            } catch (err) {
                wwLib.wwLog.error(err);
            }
        },
        async fetchUsage() {
            this.isUsageLoading = true;
            try {
                const { data } = await axios.get(`${URL}/ai/tokens/usage`);
                this.usage.tokens = data;
            } catch (err) {
                wwLib.wwNotification.open({ text: { en: 'Failed to fetch usage' }, color: 'red' });
            } finally {
                this.isUsageLoading = false;
            }
        },
        getCompactNumber(nbr) {
            return new Intl.NumberFormat(navigator.language, {
                notation: 'compact',
            }).format(nbr);
        },
        formatDate(date) {
            return moment(date).format('MMM DD, YYYY');
        },
    },
    async mounted() {
        this.handleOptionsModal();
        this.fetchUsage();
    },
    beforeDestroy() {
        document.removeEventListener('click', event => this.clickListener(event));
    },
};
</script>

<style lang="scss" scoped>
.sidebar {
    height: 100%;
    min-width: 304px;
    max-width: 304px;
    z-index: 2;
    color: var(--ww-color-dark-600);

    background-color: white;
    box-shadow: 1px 0px 0px #f4f5f7;

    display: flex;
    flex-flow: column;
    user-select: none;

    .top-side,
    .bottom-side {
        width: 100%;
    }

    #profile {
        .profileInfo-and-options-container {
            padding: 18px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .profile-info {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .user-avatar {
                height: 32px;
                width: 32px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--ww-color-red-500);
                border: 2px solid var(--ww-color-red-500);
                &__image {
                    width: 100%;
                    height: 100%;
                    border-radius: inherit;
                    background-size: cover;
                }
                p {
                    font-size: 18px;
                    font-weight: 500;
                    color: white;
                }
            }

            .user-info {
                margin-left: 12px;

                .name {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                    color: var(--ww-color-dark-700);
                }
                .email {
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: -0.5px;
                    color: var(--ww-color-dark-400);
                }
            }

            .options-container {
                position: relative;

                .from {
                    transition: 0.4s;

                    &:hover {
                        cursor: pointer;
                        transform: scale(1.2);
                        transition: 0.4s;
                    }
                }

                .options-popups {
                    position: absolute;
                    left: 48px;
                    top: 0px;
                    z-index: 900;
                    white-space: nowrap;
                    width: fit-content;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    border-radius: 8px;
                    border: 1px solid #f4f7f7;
                    box-shadow: 0px 2px 8px rgba(30, 35, 36, 0.12);

                    .admin-mode {
                        display: flex;
                        align-items: center;
                        padding: var(--ww-spacing-01) var(--ww-spacing-02);
                        &__radio {
                            margin-right: var(--ww-spacing-02);
                        }
                    }

                    .options-link {
                        text-decoration: none;
                        padding: 5px;
                        padding-left: 10px;
                        padding-right: 10px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #394446;
                        transition: 0.4s;

                        &:first-child {
                            padding-top: 10px;
                        }

                        &:last-child {
                            padding-bottom: 10px;
                        }

                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        &.signout {
                            cursor: pointer;
                            color: var(--ww-color-red-500);
                        }

                        &.visited {
                            text-decoration: none;
                            color: #394446;
                        }
                    }
                }
            }
        }

        .links {
            margin: 0px 10px 10px 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            a {
                text-decoration: none;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-radius: var(--ww-spacing-01);
                margin: 2px 0;

                .page-link {
                    width: 100%;
                    height: 100%;
                    margin: var(--ww-spacing-02) 0;
                    padding: 0 var(--ww-spacing-01);

                    max-width: 288px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 16px;
                    color: #394446;
                    transition: 0.4s;

                    .label {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;

                        svg {
                            margin-right: 10px;
                        }

                        .link-icon > path {
                            transition: 0.4s;
                        }
                        .link-icon-stroke > path {
                            transition: 0.4s;
                        }
                    }

                    .label-count {
                        margin-right: var(--ww-spacing-00);
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 21px;
                        height: 18px;
                        background: #e2f3fe;
                        border-radius: 4px;

                        font-weight: 600;
                        font-size: 12px;
                        color: #099af2;
                    }
                }

                &:hover,
                &.router-link-active {
                    background-color: var(--ww-color-blue-100);
                    transition: all 0.4s;

                    .page-link {
                        .label {
                            color: var(--ww-color-blue-500);
                            transition: all 0.4s;

                            svg.link-icon > path {
                                fill: var(--ww-color-blue-500);
                                transition: all 0.4s;
                            }

                            svg.link-icon-stroke > path {
                                stroke: var(--ww-color-blue-500);
                                transition: all 0.4s;
                            }
                        }
                    }
                }
            }
        }
    }

    .workspaces {
        .workspaces-label {
            padding: var(--ww-spacing-03) 0;
            margin: 0 var(--ww-spacing-03);
            border-top: 1px solid var(--ww-color-dark-300);
        }
    }

    .resources {
        .resources-label {
            padding: var(--ww-spacing-03) 0;
            margin: 0 var(--ww-spacing-03);
            border-top: 1px solid var(--ww-color-dark-300);
        }
    }

    .workspaces-list {
        overflow: auto;
    }
    .workspace-item,
    .resource-item {
        height: 50px;
        margin: var(--ww-spacing-00) var(--ww-spacing-02);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: var(--ww-spacing-03) var(--ww-spacing-03);
        border-radius: var(--ww-border-radius-02);
        color: var(--ww-color-dark-600);
        transition: all 0.2s ease;
        &.-small {
            height: auto;
            padding: var(--ww-spacing-02) var(--ww-spacing-03);
        }
        &:hover,
        &.router-link-active {
            cursor: pointer;
            background-color: var(--ww-color-blue-100);
            transition: all 0.4s;
            color: var(--ww-color-blue-500);
        }

        .workspace-avatar,
        .resource-icon {
            min-height: 18px;
            height: 18px;
            min-width: 18px;
            width: 18px;
            border-radius: var(--ww-border-radius-01);
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--ww-color-white);

            &.academy {
                color: var(--ww-color-green-700);
            }
            &.community {
                color: var(--ww-color-yellow-500);
            }
            &.chat {
                color: var(--ww-color-purple-500);
            }
            &.tutorial {
                color: var(--ww-color-blue-500);
            }
        }

        .resource-icon {
            color: var(--icon-color);
        }

        .workspace-name,
        .resource-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            text-decoration: none;
            margin-left: var(--ww-spacing-03);
        }
    }
    .workspace-create {
        margin-top: var(--ww-spacing-01);
        color: var(--ww-color-dark-500);
        &:hover {
            cursor: pointer;
            background-color: var(--ww-color-dark-200);
            transition: all 0.4s;
        }
        .icon {
            margin-right: var(--ww-spacing-03);
        }
    }

    .sidebar-footer {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .logo {
            margin-bottom: var(--ww-spacing-05);
            fill: #1e2324;
        }
    }
}

.toggleModal-enter-active,
.toggleModal-leave-active {
    transition: all 0.3s;
}
.toggleModal-enter,
.toggleModal-leave-to {
    opacity: 0;
    transform: translateX(-8px);
}

.profileAppear-enter-active,
.profileAppear-leave-active {
    transition: all 0.5s;
}
.profileAppear-enter,
.profileAppear-leave-to {
    opacity: 0;
    transform: translateX(-8px);
}
</style>
